<template>
  <div class="header">
    <img
      v-if="type === 1"
      class="header-logo"
      @click="skip('/')"
      src="@/assets/images/logo-white.png"
    />
    <img
      v-if="type === 2"
      @click="skip('/')"
      class="header-logo"
      src="@/assets/images/logo-multicolour.png"
    />
    <div :class="['header-navigation', { 'header-navigation2': type === 2 }]">
      <div @click="skip('/')">首页</div>
      <div @click="handleScroll">产品介绍</div>
      <div @click="skip('/resource')">资源中心</div>
    </div>
    <div :class="['header-btns', { 'header-btns2': type === 2 }]">
      <div class="language">EN</div>
      <div class="logon" @click="skip('/login?loginType=3')">登录</div>
      <div class="register" @click="skip('/login')">注册</div>
    </div>
    <img
      class="storage-right"
      src="@/assets/images/storage-right.png"
      alt="图片"
    />
  </div>
</template>

<script>
export default {
  props: ["type"],
  methods: {
    // 跳转
    skip(url) {
      this.$router.push({
        path: url,
      });
    },
    //锚链接
    handleScroll() {
      this.skip("/home?type=1");
      this.$emit("handleScroll");
    },
  },
  created() {
    console.log("type", this.type);
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  font-size: 14px;
  color: #ffffff;
  .header-logo {
    width: 90px;
    height: 40px;
    margin-left: 53px;
    cursor: pointer;
  }
  .header-navigation {
    width: calc(100% - 438px);
    display: flex;
    justify-content: space-around;
    div {
      cursor: pointer;
    }
  }
  .header-navigation2 {
    color: #232323;
  }
  .header-btns {
    width: 340px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 44px;
    .language {
      width: 28px;
      height: 28px;
      line-height: 28px;
      margin-right: 42px;
      text-align: center;
      border: 1px solid #ffffff;
      font-size: 14px;
      border-radius: 28px;
      cursor: pointer;
    }
    .logon {
      width: 109px;
      height: 44px;
      line-height: 44px;
      border-radius: 22px;
      text-align: center;
      background: #ffffff;
      color: #4578ff;
      cursor: pointer;
      margin-right: 42px;
    }
    .register {
      cursor: pointer;
    }
  }
  .header-btns2 {
    color: #232323;
    .language {
      border-color: #232323;
    }
    .logon {
      color: #fff;
      background-color: #4578ff;
    }
  }
  .storage-right {
    display: none;
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
}
</style>
<style  lang="scss" scoped>
@media only screen and (max-width: 750px) {
  .header {
    .header-navigation {
      display: none;
    }
    .header-btns {
      display: none;
    }
    .header-logo{
      margin-left: 8px;
    }
    .storage-right{
      display: block;
    }
  }
}
</style>