<template>
  <footer>
    <img
      class="logo-multicolour"
      @click="skip('/')"
      src="@/assets/images/logo-multicolour.png"
    />
    <div class="line"></div>
    <div class="footer-content">
      <div class="about">
        <div class="title">关于问卷集</div>
        <p>
          问卷集是一个高效、精准、真实的数据聚合平台；深耕10年只为客户精准提供全球样本，只为流量平台轻松实现流量变现；
        </p>
      </div>
      <div class="glibal">
        <div class="title">公司</div>
        <div class="ietnam">
          <div @click="skip('/resource?index=1&id=8')">联系我们</div>
          <div @click="skip('/resource?index=1&id=9')">资源中心</div>
          <div @click="skip('/resource?index=1&id=10')">帮助中心</div>
          <div @click="skip('/resource?index=1&id=11')">关于我们</div>
        </div>
      </div>
      <div class="glibal">
        <div class="title">精选</div>
        <div class="ietnam">
          <div @click="skip('/resource')">我要投放问卷</div>
          <div @click="skip('/resource')">我要流量变现</div>
        </div>
      </div>
      <div class="glibal glibal-imgs">
        <div class="glibal-box">
          <img class="qrcode" src="@/assets/images/wxgzh.jpg" alt="图片" />
          <div class="glibal-bottom">
            <img src="@/assets/images/wenxin.png" alt="图片" />
            <div class="glibal-title">微信公众号</div>
          </div>
        </div>
        <div class="glibal-box">
          <img class="qrcode" src="@/assets/images/kfwx.png" alt="图片" />
          <div class="glibal-bottom">
            <img src="@/assets/images/wenxin.png" alt="图片" />
            <div class="glibal-title">客服微信</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-imgs">
      <img src="@/assets/images/home/facebook.png" />
      <img src="@/assets/images/home/bird.png" />
      <img src="@/assets/images/home/in.png" />
    </div>
    <div class="line"></div>

    <div class="copyright">
      <div @click="jumpOut">
        Copyright ©2016-2023 南京百数来信息科技有限公司 苏ICP备17022236号-3
      </div>
      <div>
        <a @click="skip('/resource')">Privacy & Terms</a> <span></span>
        <a>Cookies notice & Conditions</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  methods: {
    // 跳转
    skip(url) {
      this.$router.push({
        path: url,
      });
    },
    //跳出
    jumpOut() {
      window.open("https://beian.miit.gov.cn");
    },
  },
};
</script>
<style lang="scss" scoped>
/* 页脚 */
footer {
  width: calc(100% - 280px);
  min-height: 650px;
  background: #f4f5f5;
  padding: 0 140px;
  margin-top: 20px;
}
.logo-multicolour {
  width: 90px;
  height: 40px;
  margin-top: 78px;
  margin-bottom: 12px;
  cursor: pointer;
}
footer > .line {
  width: 100%;
  height: 0px;
  opacity: 1;
  border-top: 1px solid #e9ecee;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  margin-top: 38px;
}
.footer-content .title {
  font-size: 16px;
  font-weight: bold;
  color: #232323;
}
.footer-content .about {
  width: 255px;
}
.footer-content .about p {
  margin-top: 20px;
  font-size: 14px;
  line-height: 29px;
}
.glibal > .ietnam {
  font-size: 14px;
  margin-top: 20px;
}
.glibal > .ietnam > div {
  margin-bottom: 16.25px;
  cursor: pointer;
}
.glibal-imgs {
  display: flex;
  align-items: center;
  .glibal-box {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .qrcode {
      width: 100px;
    }
    .glibal-bottom {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: center;
      img {
        width: 16px;
        margin-right: 5px;
      }
      .glibal-title {
        font-size: 14px;
      }
    }
  }
}
.footer-imgs {
  display: flex;
  margin-top: 47px;
  margin-bottom: 40px;
}
.footer-imgs > img {
  width: 46px;
  height: 46px;
  margin-right: 24px;
}
.copyright {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #232323;
}
.copyright > div {
  margin-top: 50px;
}
.copyright a {
  color: #232323;
  cursor: pointer;
}
span {
  height: 8px;
  border-left: 1px solid #707070;
  display: inline-block;
  margin-left: 11px;
  margin-right: 11px;
}
</style>
<style scoped>
@media only screen and (max-width: 1000px) {
  footer {
    width: calc(100% - 37.5px);
    min-height: 416.875px;
    background: #f4f5f5;
    padding-left: 18.75px;
    padding-right: 18.75px;
    padding-bottom: 20px;
    margin-top: 20px;
  }
  .logo-multicolour {
    margin-top: 20px;
  }
  .footer-content {
    flex-wrap: wrap;
  }
  .footer-content .about {
    width: 100%;
    margin-bottom: 20px;
  }
  .copyright {
    flex-wrap: wrap;
  }
  .copyright > div {
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 750px) {
  footer {
    display: none;
  }
}
</style>